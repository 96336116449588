<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/home_hero.jpg')"
      :mobileImage="require('@/assets/images/home_hero_mobile.jpg')"
      :mobileHeight="'100%'"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col
            cols="12"
            md="8"
            offset-md="4"
            lg="6"
            offset-lg="5"
            xl="5"
            offset-xl="6"
          >
            <b-row>
              <b-col class="hero-text p-5">
                <h1 class="h1 font-weight-bolder mb-4 mt-2">
                  The Fastest Way to Your Best Credit Score<sup>&trade;</sup>
                </h1>
                <p class="h4 mb-3">
                  Use ScoreMaster<sup>&reg;</sup> to quickly add
                  <br class="d-none d-xl-block" />points to your credit score.
                </p>
                <SignupLinkButton
                  :to="{ name: 'IndividualSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                  >Sign Up Now!</SignupLinkButton
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <NumbersBluebar />
    <b-container class="py-3">
      <p class="h1 text-center my-5 font-weight-bolder">
        How Many Points <br class="d-md-none" />Do You Have?
      </p>
      <div class="mb-5 pt-3 d-grid">
        <img
          class="img-fluid mr-auto d-block"
          src="@/assets/images/home_hero_phone.jpg"
          alt="ScoreMaster®"
        />
        <div id="hero-text" class="pr-lg-0">
          <ul class="list-unstyled pt-2 pb-2">
            <li class="mb-3">
              Enroll in 2 minutes.
            </li>
            <li class="mb-3">
              Get your points, fast.
            </li>
            <li class="mb-3">
              Get better deals!
            </li>
          </ul>
          <b-button
            :to="{ name: 'IndividualBenefits' }"
            pill
            size="lg"
            variant="secondary"
            class="w-100 text-center"
          >
            See My Benefits
          </b-button>
        </div>
        <div class="hero-subtext">
          <p class="mt-4 pr-4 pr-md-0">
            Eliminate the guesswork with your credit score. Most Americans have
            plus points to add to their credit score, but no idea how to get
            them. ScoreMaster<sup>&reg;</sup> makes it easy.
          </p>
          <p class="pr-4">
            You'll also understand the credit score consequences if you spend
            too much or if you're the victim of credit card theft.
          </p>
          <p class="pr-4">
            Credit score changes typically apply to all credit scores similarly,
            including FICO<sup>&reg;</sup> &amp; VantageScore® versions, since
            TransUnion<sup>&reg;</sup>, Experian<sup>&reg;</sup>, and
            Equifax<sup>&reg;</sup> analyze your credit utilization to determine
            your scoring.*
          </p>
        </div>
      </div>
      <b-row align-h="center" class="pt-3 pt-lg-5 text-center">
        <b-col cols="12" md="8" lg="6">
          <p class="h1 font-weight-bolder">
            More Features to Protect Your Best Credit Score
          </p>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-5 text-center">
        <b-col cols="6" lg="2" class="mb-5">
          <IconSpendingImpact style="height:69px;" class="icon icon-primary" />
          <p class="h5 font-weight-bold mt-3">Spending <br />Impact</p>
        </b-col>
        <b-col cols="6" lg="2" class="mb-5">
          <IconScoreBuilder style="height:69px;" class="icon icon-primary" />
          <p class="h5 font-weight-bold mt-3">
            Score<br />Builder<sup>&reg;</sup>
          </p>
        </b-col>
        <b-col cols="6" lg="2" class="mb-5">
          <IconMoneyManager style="height:69px;" class="icon icon-primary" />
          <p class="h5 font-weight-bold mt-3">Money <br />Manager</p>
        </b-col>
        <b-col cols="6" lg="2" class="mb-5">
          <IconInsurance style="height:69px;" class="icon icon-primary" />
          <p class="h5 font-weight-bold mt-3">
            $1 Million Fraud <br />Insurance
          </p>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mb-5">
        <b-col cols="12" lg="4" class="text-center">
          <b-button
            to="/features/"
            pill
            size="lg"
            variant="secondary"
            class="w-100 text-center"
          >
            See All Features
          </b-button>
        </b-col>
      </b-row>
      <b-row align-h="center" class="pt-3 pt-lg-5 text-center">
        <b-col cols="12">
          <p class="h1 font-weight-bolder">
            See What Our <br class="d-md-none" />Users Are Saying
          </p>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-5 text-center">
        <b-col cols="12" lg="4">
          <Testimonial
            imgFile="testimonial_1.png"
            imgAlt="JB Testimonial"
            author="J.B. in Irvine, CA"
            testimony="The accurate information helps focus & target specific needs to get your credit score up quickly"
          />
        </b-col>
        <b-col cols="12" lg="4">
          <Testimonial
            imgFile="testimonial_2.png"
            imgAlt="JB Testimonial"
            author="L.C. in Boston, MA"
            testimony="ScoreMaster<sup>&reg;</sup> is the best! It's helped so much, lots
              of control, ease of use!"
          />
        </b-col>
        <b-col cols="12" lg="4">
          <Testimonial
            imgFile="testimonial_3.png"
            imgAlt="JB Testimonial"
            author="S.R. in Erie, PA"
            testimony="ScoreMaster<sup>&reg;</sup> is an amazing tool! It tells you
              exactly what you need to do to raise your score."
          />
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="px-0">
      <p id="disclaimer" class="text-left">
        <small class="d-block mb-2">
          <small class="font-weight-semibold"
            >* The credit score changes shown in ScoreMaster<sup>&reg;</sup>
            typically apply to all credit scores the same, including all
            FICO<sup>&reg;</sup> &amp; VantageScore<sup>&reg;</sup> versions.
            This is because all credit scores are derived from
            TransUnion<sup>&reg;</sup>, Experian<sup>&reg;</sup> and Equifax<sup
              >&reg;</sup
            >
            where your payments and spending are reported.</small
          >
        </small>
        <small class="d-block mb-2">
          <small
            >The testimonial reviews are real reviews from verified purchases.
            However, all photography is for illustrative purposes only.</small
          ></small
        >
      </p>
    </b-container>
  </div>
</template>

<script>
import NumbersBluebar from '@/components/common/NumbersBluebar'
import IconSpendingImpact from '@/assets/svg/icon_cart.svg'
import IconScoreBuilder from '@/assets/svg/icon_SB.svg'
import IconMoneyManager from '@/assets/svg/icon_MM.svg'
import IconInsurance from '@/assets/svg/icon_FI.svg'
import Testimonial from '@/components/common/Testimonial'

export default {
  name: 'Home',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.scoremaster.com/',
        },
      ],
    }
  },
  data() {
    return {
      schemaData: [
        {
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@type': 'ProfessionalService',
              '@id': 'kg/g/:',
              name: 'ScoreMaster',
              description:
                'We are a group of fun and creative people building unique and patented technologies for the consumer money, credit & identity space. We started in 2003 with the idea that technology should allow consumers to interact with their banks, creditors, and other institutions using a simple button. So, we noodled a lot and built products that make people better users of their money & credit.',
              telephone: '+18772100180',
              url: 'https://www.scoremaster.com/',
              logo:
                'https://www.find-us-here.com/images/business_images/amp/scoremaster_main_photo.png',
              hasMap: 'https://www.google.com/maps?cid=11670868934081200217',
              additionalProperty: 'https://en.wikipedia.org/wiki/Credit_score',
              openingHours:
                'Mo 0800-1700 Tu 0800-1700 We 0800-1700 Th 0800-1700 Fr 0800-1700',
              disambiguatingDescription:
                'We are a group of fun and creative people building unique and patented technologies for the consumer money, credit & identity space. We started in 2003 with the idea that technology should allow consumers to interact with their banks, creditors, and other institutions using a simple button. So, we noodled a lot and built products that make people better users of their money & credit.',
              alternateName: 'ScoreMaster',
              foundingDate: '2020',
              priceRange: '$$',
              currenciesAccepted: 'USD',
              paymentAccepted: 'Visa, MC, PayPal, Amex',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '2983 Michelson Dr.',
                addressLocality: 'Irvine',
                addressRegion: 'California',
                postalCode: '92612',
                addressCountry: 'United States',
              },
              contactPoint: {
                '@type': 'ContactPoint',
                contactType: 'Customer Service',
                areaServed: [
                  'US',
                  'AL',
                  'AK',
                  'AZ',
                  'AR',
                  'CA',
                  'CO',
                  'CT',
                  'DE',
                  'FL',
                  'GA',
                  'HI',
                  'ID',
                  'IL',
                  'IN',
                  'IA',
                  'KS',
                  'KY',
                  'LA',
                  'ME',
                  'MD',
                  'MA',
                  'MI',
                  'MN',
                  'MS',
                  'MO',
                  'MT',
                  'NE',
                  'NV',
                  'NH',
                  'NJ',
                  'NM',
                  'NY',
                  'NC',
                  'ND',
                  'OH',
                  'OK',
                  'OR',
                  'PA',
                  'RI',
                  'SC',
                  'SD',
                  'TN',
                  'TX',
                  'UT',
                  'VT',
                  'VA',
                  'WA',
                  'WV',
                  'WI',
                  'WY',
                  'DC',
                  'AB',
                  'BC',
                  'MB',
                  'NB',
                  'NL',
                  'NS',
                  'ON',
                  'PE',
                  'QC',
                  'SK',
                  'NT',
                  'NU',
                  'YT',
                  'IL',
                  'IL-D',
                  'IL-M',
                  'IL-Z',
                  'IL-HA',
                  'IL-TA',
                  'IL-JM',
                ],
                telephone: '+18772100180',
              },
              image: {
                '@type': 'ImageObject',
                name: "Score Master's Logo",
                creator: 'Score Master',
                author: 'David Coulter',
                description: "Score Master's Vertical Logo",
                url:
                  'https://www.find-us-here.com/images/business_images/amp/scoremaster_main_photo.png',
                contentLocation: 'Irvine CA',
              },
              geo: {
                '@type': 'GeoCoordinates',
                name: 'ScoreMaster',
                postalCode: '92612',
                latitude: '33.6707',
                longitude: '-117.8537',
                description: 'You can find our office in this location.',
              },
              founder: [
                {
                  '@type': 'Person',
                  alternateName: 'David Coulter',
                  description:
                    'David Coulter is the owner of Score Master. As the Cheif SEO Expert, David is helping individuals, lenders and employers get credit score with as little as enrilling in 1 point and getting better deals.  ',
                  jobTitle: 'Owner',
                  telephone: '+18772100180',
                  potentialAction: {
                    '@id': 'https://www.scoremaster.com/get-started/',
                  },
                  name: 'David Coulter Owner',
                  url: 'https://www.scoremaster.com/',
                  givenName: 'David',
                  familyName: 'Coulter',
                  image:
                    'https://scoremaster.com/uploads/2020/10/29153536/ScoreMaster_david-b-coulter.png',
                  gender: 'Male',
                  worksFor: 'https://www.scoremaster.com/',
                  workLocation: 'Irvine CA',
                },
              ],
              additionalType: [
                'https://en.wikipedia.org/wiki/Credit_score',
                'https://en.wikipedia.org/wiki/Credit_rating',
                'https://en.wikipedia.org/wiki/Credit_history',
                'https://en.wikipedia.org/wiki/Credit_report_monitoring',
                'https://en.wikipedia.org/wiki/Business_credit_reports',
                'http://productontology.org/id//Credit_score',
                'http://productontology.org/id//Credit_rating',
                'http://productontology.org/id//Credit_history',
                'http://productontology.org/id//Credit_report_monitoring',
                'http://productontology.org/id//Business_credit_reports',
              ],
              mainEntityOfPage: 'kg:/g/',
              sameAs: [
                'https://www.webwiki.com/scoremaster.com',
                'https://www.facebook.com/realscoremaster/',
                'https://twitter.com/scoremaster',
                'https://www.linkedin.com/company/scoremasterfirst',
                'https://www.abc-directory.com/site/387506',
                'https://www.freelistingusa.com/listings/scoremaster',
                'http://www.iformative.com/product/scoremaster-p1814867.html',
                'http://b2bco.com/scoremaster',
                'https://coupondealsus.com/site-coupon/scoremaster.com',
                'https://www.industryhuddle.com/scoremaster',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
              ],
              knowsAbout: [
                'Credit Score',
                'Credit Rating',
                'Credit History',
                'Credit Report Monitoring',
                'Business Credit Reports',
                'Individual Credit Reports',
                'Credit Score For Lenders',
                'Credit Score For Employers',
                'Credit Points Rating',
                'Credit Score Builder',
              ],
              hasOfferCatalog: {
                '@type': 'OfferCatalog',
                name: 'ScoreMaster',
                '@id': 'https://www.scoremaster.com/',
                url: 'https://www.scoremaster.com/',
                additionalType: 'http://www.productontology.org/id/website',
                itemListElement: [
                  {
                    '@type': 'Offer',
                    itemOffered: [
                      {
                        '@type': 'Service',
                        name: 'Employee Benefits',
                        '@id': 'https://www.scoremaster.com/employee-benefits/',
                        url: 'https://www.scoremaster.com/employee-benefits/',
                        description:
                          'Give ScoreMaster® to your employees as a benefit so they can get better deals and save money.',
                      },
                      {
                        '@type': 'Service',
                        name: 'Lender Benefits',
                        '@id': 'https://www.scoremaster.com/lender-benefits/',
                        url: 'https://www.scoremaster.com/lender-benefits/',
                        description:
                          'Give ScoreMaster® to your customers to close more and better-quality deals.',
                      },
                      {
                        '@type': 'Service',
                        name: '',
                        '@id': '',
                        url: '',
                        description: '',
                      },
                      {
                        '@type': 'Service',
                        name: '',
                        '@id': '',
                        url: '',
                        description: '',
                      },
                      {
                        '@type': 'Service',
                        name: '',
                        '@id': '',
                        url: '',
                        description: '',
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
      ],
    }
  },
  components: {
    NumbersBluebar,
    IconSpendingImpact,
    IconScoreBuilder,
    IconMoneyManager,
    IconInsurance,
    Testimonial,
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
    this.$store.commit('metaData/updatePageSchema', this.schemaData)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('metaData/resetDefaultSchema')
    next()
  },
}
</script>

<style lang="scss" scoped>
.d-grid {
  display: flex;
  flex-wrap: wrap;

  img {
    width: 40%;
    height: 40%;
    padding-right: 1rem;
  }
  #hero-text {
    width: 60%;
  }
}
@media (min-width: 768px) {
  .d-grid {
    display: grid;
    grid-template-columns: 40% 60%;
    max-width: 600px;
    margin: 0 auto;
    column-gap: 2rem;
    width: auto;

    img {
      grid-row-start: 1;
      grid-row-end: span 2;
      width: auto;
      height: auto;
      padding-right: 0;
    }
    #hero-text {
      width: auto;
    }

    .hero-subtext {
      grid-column-start: 2;
    }
  }
}
@media (min-width: 991px) {
  .d-grid {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
